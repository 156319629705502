var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"marginWrapper el-row--padding"},[_c('div',{staticClass:"flex flexColumn"},[_c('el-row',[_c('el-col',[_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"flex verticleCentering spaceBetween",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("GPS Device Registration")]),_c('div',{staticClass:"textRight"},[_c('el-button',{attrs:{"type":"primary el-button--mini","plain":"","round":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('i',{staticClass:"el-icon-d-arrow-left"}),_vm._v("Back ")])],1)]),_c('el-form',{attrs:{"label-position":'left',"label-width":"150px","enctype":"multipart/form-data","multiple":""}},[_c('el-row',[_c('h4',{staticStyle:{"margin-left":"20px","margin-bottom":"10px"}},[_vm._v(" General Information ")]),_c('el-row',[_c('el-col',{attrs:{"span":10}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Device Name'),
                      'is-required': _vm.isFieldRequired('Device Name'),
                    },attrs:{"label":"Device Model Name"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"GPS Device Model Name","name":"Device Name"},model:{value:(_vm.gpsDevice.deviceName),callback:function ($$v) {_vm.$set(_vm.gpsDevice, "deviceName", $$v)},expression:"gpsDevice.deviceName"}}),(_vm.errors.has('Device Name'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Device Name")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":10,"hidden":""}},[_c('el-form-item',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.gpsDevice.isActive),expression:"gpsDevice.isActive"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.gpsDevice.isActive)?_vm._i(_vm.gpsDevice.isActive,null)>-1:(_vm.gpsDevice.isActive)},on:{"change":function($event){var $$a=_vm.gpsDevice.isActive,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.gpsDevice, "isActive", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.gpsDevice, "isActive", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.gpsDevice, "isActive", $$c)}}}}),_vm._v(" Is Active ")])],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":10}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Protocol'),
                      'is-required': _vm.isFieldRequired('Protocol'),
                    },attrs:{"label":"Protocol"}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"filterable":"","name":"Protocol","placeholder":"Please select"},model:{value:(_vm.gpsDevice.gpsProtocol),callback:function ($$v) {_vm.$set(_vm.gpsDevice, "gpsProtocol", $$v)},expression:"gpsDevice.gpsProtocol"}},_vm._l((_vm.ProtocolList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.protocol,"value":item.id}})}),1),(_vm.errors.has('Protocol'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Protocol")))]):_vm._e()],1)],1)],1)],1),_c('el-row',[_c('h4',{staticStyle:{"margin-left":"20px","margin-bottom":"10px"}},[_vm._v(" Suportive Documents ")]),_c('el-row',[_c('el-col',{attrs:{"span":20}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('NTA Certificate'),
                      'is-required': _vm.isFieldRequired('NTA Certificate'),
                    },attrs:{"label":"NTA Approval"}},[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"","id":"doc","auto-upload":false,"on-change":_vm.handleChange,"on-remove":_vm.handleRemove,"file-list":_vm.ntaCertificate,"accept":"image/*","thumbnail-mode":""}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:({ required: _vm.isFileRequired, image: true }),expression:"{ required: isFileRequired, image: true }"}],key:"NTA Certificate",attrs:{"slot":"trigger","name":"NTA Certificate","size":"small","type":"primary","round":""},slot:"trigger",model:{value:(_vm.ntaCertificate),callback:function ($$v) {_vm.ntaCertificate=$$v},expression:"ntaCertificate"}},[_c('i',{staticClass:"el-icon-upload"}),_vm._v("  Click to upload ")])],1),(_vm.errors.has('NTA Certificate'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("NTA Certificate")))]):_vm._e(),_c('detail-tag',{attrs:{"data":_vm.gpsDevice.ntaCertificateName,"dataLink":_vm.gpsDevice.ntaCertificate,"type":"editimage"}}),(_vm.errors.has(''))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("")))]):_vm._e()],1)],1)],1)],1),_c('el-row',[_c('el-col',[_c('el-form-item',{staticClass:"textRight"},[_c('el-button',{attrs:{"type":"primary","round":""},on:{"click":_vm.submit}},[_vm._v("Save")]),_c('el-button',{attrs:{"type":"danger","round":""},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("Cancel")])],1)],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }