






































































































































































import { Vue, Component } from "vue-property-decorator";
import { Input, Button, Form } from "element-ui";
import { AdminRouter } from "@/utils/routePathContsant";
import {
  CommonStore as commonstore,
  MetaModule as metaStore,
} from "@/store/modules";
import gpsProviderStore from "@/store/modules/gpsServiceProvider";
import { District, Palika, Protocol } from "@/store/models/meta";
import helpers from "@/utils/helpers";
import DetailTag from "@/components/UIComponents/DetailTag.vue";

@Component({
  components: {
    Input,
    Button,
    Form,
    DetailTag,
  },
})
export default class New extends Vue {
  isCreate: boolean = true;
  municipality: Palika[] = [];
  districts: District[] = [];
  gpServiceId: any;
  options: Protocol[] = [] as any;
  ntaCertificate: File[] = [];
  async created() {
    let vm = this;
    this.gpServiceId = commonstore.gpsServiceId;
    const id = vm.$route.query["id"];
    await metaStore.loadProtocolDD();
    if (id) {
      vm.isCreate = false;

      await gpsProviderStore.getGpsDeviceById(id);
    } else {
      gpsProviderStore.resetField();
    }
  }

  get isFileRequired() {
    if (this.$route.query.id) {
      return false;
    }
    return true;
  }

  get ProtocolList() {
    return metaStore.protocolList;
  }
  get AdminRouter() {
    return AdminRouter;
  }

  get gpsDevice() {
    return gpsProviderStore.gpsDevice;
  }

  get profile() {
    return gpsProviderStore.profile;
  }

  handleChange(file: any, fileList: any) {
    let vm = this;

    if (file.raw.type === "image/jpeg" || file.raw.type === "image/png") {
      this.ntaCertificate = [file.raw];
      vm.gpsDevice.ntaCertificate = file.raw;
    } else {
      this.$snotify.error("Please upload .jpeg or .png file");
      file = "";
      this.ntaCertificate = [];
      vm.gpsDevice.ntaCertificate = null;
    }
  }

  handleRemove(file: any, fileList: any) {
    let vm = this;
    this.ntaCertificate = [];
    vm.gpsDevice.ntaCertificate = null;
  }

  async submit() {
    let isValid = await this.$validator.validateAll();
    if (!isValid) {
      return;
    }
    let vm = this;
    commonstore.showLoading();
    if (this.gpsDevice.id) {
      // Remove File Fields without file
      helpers.removeFilesForPatch([
        { property: this.gpsDevice, fieldName: "ntaCertificate" },
      ]);
    }
    await gpsProviderStore.createGpsDevice();
    this.$snotify.success("Saved Successfully");
    commonstore.hideLoading();
    this.$router.push(
      AdminRouter.GPSDeviceList + "?id=" + commonstore.gpsServiceId
    );
  }

  async cancelForm() {
    this.$router.push(AdminRouter.GPSDeviceList + "?id=" + this.gpServiceId);
  }

  mounted() {}
  updated() {}
}
